export const notNull = (val) => val !== null && val !== undefined;

export const notEmpty = (val) => notNull(val) && val.length > 0;

export const isNull = (val) => !notNull(val);

export const isEmpty = (val) => isNull(val) || val.length === 0;

export const phoneFormat = (val) => notNull(val) && val.length > 7 ? `(${val.substr(0, 3)}) ${val.substr(3, 3)}-${val.substr(6)}` : val;

export const cleanPhone = (val) => val ? val.replace('+1', '').replace(/\D/g, '') : val;

export const creditCardType = (cc) => {
  const amex = new RegExp('^3[47]');
  const visa = new RegExp('^4[0-9]');
  const cup1 = new RegExp('^62[0-9]');
  const cup2 = new RegExp('^81[0-9]');

  const mastercard = new RegExp('^5[1-5]');
  const mastercard2 = new RegExp('^2[2-7]');

  const disco1 = new RegExp('^6011');
  const disco2 = new RegExp('^62[24568]');
  const disco3 = new RegExp('^6[45]');
  
  const diners = new RegExp('^3[0689]');
  const jcb =  new RegExp('^35');


  if (visa.test(cc)) {
    return 'VISA';
  }
  if (amex.test(cc)) {
    return 'AMEX';
  }
  if (mastercard.test(cc) || mastercard2.test(cc)) {
    return 'MASTERCARD';
  }
  if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
    return 'DISCOVER';
  }
  if (diners.test(cc)) {
    return 'DINERS';
  }
  if (jcb.test(cc)) {
    return 'JCB';
  }
  if (cup1.test(cc) || cup2.test(cc)) {
    return 'CHINA_UNION_PAY';
  }

  return '';
}

export const clearCurrency = (val, divide = false) => val == 0 ? '$0.00' : `$${parseFloat(divide ? val / 100 : val).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
export const clearCurrencyValue = (val) => val == 0 ? '0.00' : `${parseFloat(val).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

const encryptRule = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
const decryptRule = 'kJvxNWKaEOuMbTGcXdProUeHBfgqCthQiIYDjylmsnRzSALwpFVZ8960275134';

export const simpleEncryptDecrypt = (orgStr, isEncrypt = true) => {
  let encryptedString = '';
  for (let i = 0; i < orgStr.length; i++) {
    const char = orgStr.charAt(i);
    const charIndex = (isEncrypt ? encryptRule : decryptRule).indexOf(char);
    encryptedString += charIndex == -1 ? char : (isEncrypt ? decryptRule[charIndex] : encryptRule[charIndex]);
  }
  return encryptedString;
}


const moment = require('moment-timezone');

export const utcToTimezone = (dateTime, timezone, format = 'MM/DD/YYYY hh:mmA') => moment(dateTime).isValid() ? moment.utc(dateTime).tz(timezone).format(format) : false;
