import React from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { notNull } from 'utils/text';
import { MySelectContainer } from './style';
import WrapWithContainer from '../CommonWrapper';

const MySelect = ({ id, label = null, placeholder = '', value, values, options, onChange = () => {}, ...otherProps }) => (
  <MySelectContainer>
    <TextField
      id={id}
      fullWidth
      select
      label={label}
      value={notNull(value) ? value : (values && notNull(values[id]) ? values[id] : '')}
      onChange={(e) => onChange(id, e.target.value)}
      variant="outlined"
      size="small"
      {...otherProps}
      >
      {options && options.length > 0 ? options.filter(option => !!option).map((option) => {
        if (option.value !== undefined && option.label !== undefined) {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          )
        }

        return (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        )
      }) : <MenuItem />}
    </TextField>
  </MySelectContainer>
);

export default WrapWithContainer(MySelect);
