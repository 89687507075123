/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { getLoginUser } from './loginService';

const base_url = `${process.env.BACKEND_API_BASE_URL}/settings.php`;

export const settingsService = {
  admin_get_settings: async () =>
    axios.get(base_url, {
      params: {
        method: 'admin_get_settings',
        token: getLoginUser().token,
      },
    }),
  admin_save_settings: async values =>
    axios.get(base_url, {
      params: {
        method: 'admin_save_settings',
        ...values,
        token: getLoginUser().token,
      },
    }),
  admin_super_settings: async values =>
    axios.get(base_url, {
      params: {
        method: 'admin_super_settings',
        ...values,
        token: getLoginUser().token,
      },
    }),
  admin_get_communication_settings: async () =>
    axios.get(base_url, {
      params: {
        method: 'admin_get_communication_settings',
        token: getLoginUser().token,
      },
    }),
};
