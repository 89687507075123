/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';

const baseUrl = `${process.env.BACKEND_API_BASE_URL}/callcenter.php`;
const baseUrl1 = `${process.env.BACKEND_API_BASE_URL}/callcenter-call.php`;

export const isBrowser = () => typeof window !== 'undefined';

export const getCallcenterUser = () =>
  isBrowser() && window.localStorage.getItem('PergoCallcenterUser')
    ? JSON.parse(window.localStorage.getItem('PergoCallcenterUser'))
    : {};

export const callcenterSetting = () => getCallcenterUser().setting;

export const setCallcenterUser = user => {
  window.localStorage.setItem('PergoCallcenterUser', JSON.stringify(user));
};

export const setActiveDbName = (dbname, settings = false) => {
  setCallcenterUser({
    ...getCallcenterUser(),
    active_db: dbname,
    active_db_setting: settings || null,
  });
};

export const getActiveDbName = () => getCallcenterUser()?.active_db;

export const getActiveDbSetting = () => getCallcenterUser().active_db_setting;

export const isCallcenterLoggedIn = () => {
  const user = getCallcenterUser();
  return !!user.email;
};

export const logoutCallcenter = callback => {
  setCallcenterUser({});
};

export const callcenterService = {
  signup_initial: async ({ center_name, email }) =>
    axios.get(baseUrl, {
      params: {
        method: 'signup_initial',
        center_name,
        email,
      },
    }),
  resend_verify_code: async ({ email }) =>
    axios.get(baseUrl, {
      params: {
        method: 'resend_verify_code',
        email,
      },
    }),
  verify_email: async ({ email, verify_code }) =>
    axios.get(baseUrl, {
      params: {
        method: 'verify_email',
        email,
        verify_code,
      },
    }),
  update_password: async ({ email, password, tmp_verify_code }) =>
    axios.get(baseUrl, {
      params: {
        method: 'update_password',
        email,
        password,
        tmp_verify_code,
      },
    }),
  login: async ({ email, password }) =>
    axios.get(baseUrl, {
      params: {
        method: 'login',
        email,
        password,
      },
    }),
  get_linked_bases: async () =>
    axios.get(baseUrl1, {
      params: {
        method: 'get_linked_bases',
        token: getCallcenterUser().token,
      },
    }),
  get_voice_token: async ({ dbname }) =>
    axios.get(baseUrl1, {
      params: {
        method: 'get_voice_token',
        dbname,
        token: getCallcenterUser().token,
      },
    }),
  get_call_queue: async ({ dbname }) =>
    axios.get(baseUrl1, {
      params: {
        method: 'get_call_queue',
        dbname,
        token: getCallcenterUser().token,
      },
    }),
  take_voice_call: async ({ dbname }) =>
    axios.get(baseUrl1, {
      params: {
        method: 'take_voice_call',
        dbname,
        token: getCallcenterUser().token,
      },
    }),
  get_base_settings: async ({ dbname }) =>
    axios.get(baseUrl1, {
      params: {
        method: 'get_base_settings',
        dbname,
        token: getCallcenterUser().token,
      },
    }),
};
