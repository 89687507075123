/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { getLoginUser } from './loginService';

const base_url = `${process.env.BACKEND_API_BASE_URL}/payment.php`;

export const paymentService = {
  create_merchant_account: async data =>
    axios.get(base_url, {
      params: {
        method: 'create_merchant_account',
        ...data,
        token: getLoginUser().token,
      },
    }),
  create_driver_account: async data =>
    axios.get(base_url, {
      params: {
        method: 'create_driver_account',
        ...data,
        token: getLoginUser().token,
      },
    }),
  add_payment_to_merchant_account: async data =>
    axios.get(base_url, {
      params: {
        method: 'add_payment_to_merchant_account',
        ...data,
        token: getLoginUser().token,
      },
    }),
  add_payment_to_merchant_stripe_account: async data =>
    axios.get(base_url, {
      params: {
        method: 'add_payment_to_merchant_stripe_account',
        ...data,
        token: getLoginUser().token,
      },
    }),
  add_payment_to_passenger: async data =>
    axios.get(base_url, {
      params: {
        method: 'add_payment_to_passenger',
        token: getLoginUser().token,
        ...data,
      },
    }),
  remove_payment_from_passenger: async data =>
    axios.get(base_url, {
      params: {
        method: 'remove_payment_from_passenger',
        ...data,
        token: getLoginUser().token,
      },
    }),
  add_payment_to_account: async data =>
    axios.get(base_url, {
      params: {
        method: 'add_payment_to_account',
        ...data,
        token: getLoginUser().token,
      },
    }),
  remove_payment_from_account: async data =>
    axios.get(base_url, {
      params: {
        method: 'remove_payment_from_account',
        ...data,
        token: getLoginUser().token,
      },
    }),
  get_balance: async data =>
    axios.get(base_url, {
      params: {
        method: 'get_balance',
        ...data,
        token: getLoginUser().token,
      },
    }),
  reload_balance: async data =>
    axios.get(base_url, {
      params: {
        method: 'reload_balance',
        ...data,
        token: getLoginUser().token,
      },
    }),
  reload_balance_stripe: async data =>
    axios.get(base_url, {
      params: {
        method: 'reload_balance_stripe',
        ...data,
        token: getLoginUser().token,
      },
    }),
  refund_payment: async data =>
    axios.get(base_url, {
      params: {
        method: 'refund_payment',
        ...data,
        token: getLoginUser().token,
      },
    }),
  pay_subscribe_bill: async data =>
    axios.get(base_url, {
      params: {
        method: 'pay_subscribe_bill',
        ...data,
        token: getLoginUser().token,
      },
    }),
};
