const Config = {
  PAGE_SIZE: 25,
  STATES_LIST: [
    'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC',
    'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS',
    'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO',
    'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP',
    'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN',
    'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY', '--',
    'AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'NT', 'NU', 'ON', 'PE',
    'QC', 'SK', 'YT'
  ],
  VEHICLE_MAKE: ['Acura', 'Aston Martin', 'Audi', 'BMW', 'Bentley', 'Buick', 'Cadillac', 'Chevrolet', 'Chrysler', 'Daewoo', 'Dodge', 'Ford', 'Freightliner', 'GMC', 'Genesis', 'Geo', 'HUMMER', 'Honda', 'Hyundai', 'Infiniti', 'Isuzu', 'Jaguar', 'Jeep', 'Kia', 'Land Rover', 'Lexus', 'Lincoln', 'Mazda', 'Mini', 'Maybach', 'Mercedes-Benz', 'Mercury', 'Mitsubishi', 'Nissan', 'Oldsmobile', 'Plymouth', 'Pontiac', 'Ram', 'Rolls-Royce', 'Scion', 'Subaru', 'Suzuki', 'Tesla', 'Toyota', 'Volkswagen', 'Volvo'],
  VEHICLE_TYPE_LIST: ['Town Car', 'Van/Minivan', 'Sedan', 'Stretch', 'Van - Standard', 'Van - Luxury', 'Hybrid', 'SUV', 'Handicap', 'Compact', 'Taxi', 'Wagon', 'Ambulance', 'Bus', 'Other'],
  VEHICLE_TYPE: [
    {
      label: 'Town Car',
      value: 0,
    },
    {
      label: 'Van/Minivan',
      value: 1,
    },
    {
      label: 'Sedan',
      value: 2,
    },
    {
      label: 'Stretch',
      value: 3,
    },
    {
      label: 'Van - Standard',
      value: 4,
    },
    {
      label: 'Van - Luxury',
      value: 5,
    },
    {
      label: 'Hybrid',
      value: 6,
    },
    {
      label: 'SUV',
      value: 7,
    },
    {
      label: 'Handicap',
      value: 8,
    },
    {
      label: 'Compact',
      value: 9,
    },
    {
      label: 'Taxi',
      value: 10,
    },
    {
      label: 'Wagon',
      value: 11,
    },
    {
      label: 'Ambulance',
      value: 12,
    },
    {
      label: 'Bus',
      value: 13,
    },
    {
      label: 'Other',
      value: 14,
    },
  ],
  MAS_VEHICLE_TYPE: [
    {
      label: 'Ambulance',
      value: 0,
    },
    {
      label: 'Livery',
      value: 1,
    },
    {
      label: 'Personal Car',
      value: 2,
    },
    {
      label: 'Specialty',
      value: 3,
    },
    {
      label: 'Stretcher',
      value: 4,
    },
    {
      label: 'Taxi',
      value: 5,
    },
    {
      label: 'Wheelchair',
      value: 6,
    }
  ],
  VEHICLE_COLOR: [
    {
      label: 'Black',
      value: 'Black',
    },
    {
      label: 'White',
      value: 'White',
    },
    {
      label: 'Gray',
      value: 'Gray',
    },
    {
      label: 'Red',
      value: 'Red',
    },
    {
      label: 'Dark Red',
      value: 'Dark Red',
    },
    {
      label: 'Blue',
      value: 'Blue',
    },
    {
      label: 'Dark Blue',
      value: 'Dark Blue',
    },
    {
      label: 'Silver',
      value: 'Silver',
    },
    {
      label: 'Green',
      value: 'Green',
    },
    {
      label: 'Orange',
      value: 'Orange',
    },
    {
      label: 'Brown',
      value: 'Brown',
    },
    {
      label: 'Beige',
      value: 'Beige',
    },
    {
      label: 'Gold',
      value: 'Gold',
    },
    {
      label: 'Purple',
      value: 'Purple',
    },
    {
      label: 'Gold',
      value: 'Gold',
    },
  ],
  FARES_INCREMENT: [
    '1', '1/5', '1/4', '1/2', '3/4', '1/7', '1/8',
    '3/8', '5/8', '7/8', '1/10', '3/10', '7/10', '9/10',
    '1/12', '5/12', '7/12', '11/12', '1/16', '3/16', '5/16',
    '7/16', '9/16', '11/16', '13/16', '15/16'
  ],
  DEFAULT_FARE_MULTIPLIERS: [
    {'name':'townCar','desc':'Town Car','multiplier':'1.00','enabled':1},
    {'name':'miniVan', 'desc':'Mini Van','multiplier':'1.00','enabled':1},
    {'name':'sedan', 'desc':'Sedan','multiplier':'1.00','enabled':1},
    {'name':'stretch', 'desc':'Stretch','multiplier':'1.00','enabled':1},
    {'name':'vanStandard', 'desc':'Van - Standard','multiplier':'1.00','enabled':1},
    {'name':'vanLuxury', 'desc':'Van - Luxury','multiplier':'1.00','enabled':1},
    {'name':'hybrid', 'desc':'Hybrid','multiplier':'1.00','enabled':1},
    {'name':'suv', 'desc':'SUV','multiplier':'1.00','enabled':1},
    {'name':'handicap', 'desc':'Handicap','multiplier':'1.00','enabled':1},
    {'name':'compact', 'desc':'Compact','multiplier':'1.00','enabled':1},
    {'name':'taxi', 'desc':'Taxi','multiplier':'1.00','enabled':1},
    {'name':'wagon', 'desc':'Wagon','multiplier':'1.00','enabled':1},
    {'name':'ambulance', 'desc':'Ambulance','multiplier':'1.00','enabled':1},
    {'name':'bus', 'desc':'Buls','multiplier':'1.00','enabled':1},
    {'name':'other', 'desc':'Other','multiplier':'1.00','enabled':1},
  ],
  RELOAD_BALANCE: [
    {
      label: '$5',
      value: 5,
    },
    {
      label: '$25',
      value: 25,
    },
    {
      label: '$50',
      value: 50,
    },
    {
      label: '$75',
      value: 75,
    },
    {
      label: '$100',
      value: 100,
    },
  ],
  PHONE_MASK: ['(',/\d/,/\d/,/\d/,')',' ',/\d/,/\d/,/\d/,'-',/\d/,/\d/,/\d/,/\d/],
  CANCEL_FEE_STATUS: [
    {
      label: 'Dispatched',
      value: '1',
    },
    {
      label: 'Accepted',
      value: '2',
    },
    {
      label: 'Arrived',
      value: '3',
    },
  ],
  MANAGER_ACCESS: [
    {
      value: 'Jobs',
      label: 'Job Records',
    },
    {
      value: 'Devices',
      label: 'Approved Devices',
    },
    {
      value: 'Drivers',
      label: 'Drivers',
    },
    {
      value: 'Vehicles',
      label: 'Vehicles',
    },
    {
      value: 'Passengers',
      label: 'Passengers',
    },
    {
      value: 'Accounts',
      label: 'Accounts',
    },
    {
      value: 'Invoices',
      label: 'Invoices',
    },
    {
      value: 'BlockedLocations',
      label: 'Blocked Locations',
    },
    {
      value: 'Settings',
      label: 'Settings',
    },
    {
      value: 'VehicleInspection',
      label: 'Vehicle Inspection',
    },
  ]
};

export default Config;