import axios from 'axios';

const base_url = `${process.env.BACKEND_API_BASE_URL}/login.php`;

export const isBrowser = () => typeof window !== 'undefined';

export const getLoginUser = () =>
  isBrowser() && window.localStorage.getItem('PergoUser') ? JSON.parse(window.localStorage.getItem('PergoUser')) : {};

export const siteSetting = () => getLoginUser().setting;

export const siteZones = () => getLoginUser().zones;

export const setLoginUser = user => {
  window.localStorage.setItem('PergoUser', JSON.stringify(user));
};

export const isLoggedIn = () => {
  const user = getLoginUser();
  return !!user.dbname;
};

export const isDisabledLoggedIn = () => {
  const user = getLoginUser();
  return !!user.dbname && user.role === 'supervisor_disable';
};

export const logout = callback => {
  setLoginUser({});
};

export const loginService = {
  login: async (email, password) =>
    axios.get(base_url, {
      params: {
        method: 'login',
        email,
        password,
      },
    }),
  update_password: async (email, password, status = -1) =>
    axios.get(base_url, {
      params: {
        method: 'update_password',
        email,
        password,
        status,
      },
    }),
  admin_get_manager_table: async order =>
    axios.get(base_url, {
      params: {
        method: 'admin_get_manager_table',
        order,
        token: getLoginUser().token,
      },
    }),
  admin_invite_manager: async data =>
    axios.get(base_url, {
      params: {
        method: 'admin_invite_manager',
        ...data,
        token: getLoginUser().token,
      },
    }),
  admin_update_manager: async data =>
    axios.get(base_url, {
      params: {
        method: 'admin_update_manager',
        ...data,
        token: getLoginUser().token,
      },
    }),
  admin_del_manager: async id =>
    axios.get(base_url, {
      params: {
        method: 'admin_del_manager',
        id,
        token: getLoginUser().token,
      },
    }),
  admin_change_status: async id =>
    axios.get(base_url, {
      params: {
        method: 'admin_change_status',
        id,
        token: getLoginUser().token,
      },
    }),
  admin_invite_check: async token =>
    axios.get(base_url, {
      params: {
        method: 'admin_invite_check',
        token,
      },
    }),
  get_reservation_base_setting: async data =>
    axios.get(base_url, {
      params: {
        method: 'get_reservation_base_setting',
        ...data,
      },
    }),
  passenger_reservation_login: async data =>
    axios.get(base_url, {
      params: {
        method: 'passenger_reservation_login',
        ...data,
      },
    }),
};
