import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

const CircularProgressContext = React.createContext({
  showCircularProgress: () => {},
  hideCircularProgress: () => {},
});
export { CircularProgressContext };

const CircularProgressOverlay = ({
  children
}) => {
  const [active, setActive] = useState(false);
  const [text, setText] = useState('Processing...')
  const showCircularProgress = (message = null) => {
    setText(message || 'Processing...');
    setActive(true);
  } 
  const hideCircularProgress = () => {
    setActive(false);
  }

  return (
    <CircularProgressContext.Provider
      value={{
        showCircularProgress,
        hideCircularProgress
      }}
    >
      <div className="circular-progress-overlay-children--wrapper">
        {children}
      </div>

      <OverlayContainer active={active}>
        <div className="overlay-container--content">
          <CircularProgress size={25} style={{ color: 'white' }} />
          <span className="circular-progress-overlay--text">{text}</span>
        </div>
      </OverlayContainer>
    </CircularProgressContext.Provider>
  )
}

export default CircularProgressOverlay;

const OverlayContainer = styled.div`
  left: 0;
  top: 0;
  width: ${props => props.active ? '100vw' : 0};
  height: ${props => props.active ? '100vh' : 0};
  background: #11111188;
  display: flex;
  opacity: ${props => props.active ? 1 : 0};
  z-index: 10000;
  position: fixed;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease-in;

  .overlay-container--content {
    display: ${props => props.active ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    margin: 0 auto 60px;

    .circular-progress-overlay--text {
      font-family: 'Roboto';
      font-weight: 500;
      font-size: 25px;
      color: white;
      margin-left: 10px;
    }
  }
`;